
            @import 'src/styles/mixins.scss';
        
.answer {
    transition-property: all;
    transition-duration: 250ms;
    p,
    ul,
    ol {
        @apply mb-3;
    }
    li {
        @apply mb-1;
    }
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}
.border {
    border: 2px solid #e5e7eb;
}
.plus {
    transition: all 0.2s ease-in-out;
    width: 20px;
    height: 3px;
    background-color: black;
    &:before {
        transition: all 0.2s ease-in-out;
        position: absolute;
        content: "";
        width: 20px;
        height: 3px;
        background-color: black;
        transform: rotate(90deg);
    }
}
.minus {
    &:before {
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
    }
}
